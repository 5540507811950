/* Universal box-sizing as per https://css-tricks.com/box-sizing/ */
html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* Universal box-sizing as per https://css-tricks.com/box-sizing/ */
*, *:before, *:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" 1;
    -moz-font-feature-settings: "kern" 1;
    -o-font-feature-settings: "kern" 1;
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    text-rendering: optimizeLegibility; /* Beware of performance issues in mobile - see https://css-tricks.com/almanac/properties/t/text-rendering/ */
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;

    &.is-offset {
        margin-top: -195px;
    }
}

a {
    text-decoration: none;
    color: #443E40;
}
