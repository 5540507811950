#banner {
    @include retina-background-image($img-banner);
    background-color: #8e8387;
    outline: none;
    display: block;
    width: $banner-width;
    height: $banner-height;
    margin: 0 auto 35px;
    position: relative; /* To ensure the bottom border is positioned correctly */

    h1 {
        // Font is included from Google Fonts automatically unless a custom banner is used
        // See _config.yml for details
        font-family: $banner-font-family;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0.03em;
        color: #fefefe;
        position: absolute;
        bottom: 20px;
        width: 100%;
    }

    &::after {
        display: block;
        position: absolute;
        top: $banner-height;
        left: $banner-border-offset;
        content: "";
        background: #443E40;
        height: $banner-border-width;
        width: $banner-width - ($banner-border-offset* 2);
    }

    @include min-breakpoint($image-splitting-breakpoint) {
        margin-bottom: $banner-margin;
    }
}

#footer {
    margin-top: 50px;
    text-align: center;

    &::after {
        @include retina-background-image($img-dude);
        display: block;
        content: "";
        width: 65px;
        height: 158px;
        margin: 20px auto 50px;
    }

    a {
        // Overwrite default link color
        color: #222;

        &:hover {
            // ... but allow the hover color to work as normal
            color: $hover-color;
        }
    }

    p {
        margin: 0 0 9px;
        line-height: 1.3;
        font-size: 19px;
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;

        @include min-breakpoint($image-splitting-breakpoint) {
            font-size: 18px;
            line-height: 1;
        }
    }
}

section[role="main"] {
    width: 90%;
    margin: 0 auto;

    @include min-breakpoint($desktop-breakpoint) {
        width: $layout-width;
    }
}
