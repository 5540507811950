@mixin min-breakpoint($point) {
    @media only screen and (min-width: $point) {
        @content;
    }
}

@mixin max-breakpoint($point) {
    @media only screen and (max-width: $point) {
        @content;
    }
}

